<template>
  <div class="tw-mx-auto tw-mt-8 tw-max-w-md">
    <header>
      <h1 class="tw-text-center tw-font-sans tw-text-3xl tw-font-bold tw-text-gray-900">Log in</h1>
    </header>
    <login-form class="box" :redirect="redirect" />
  </div>
</template>

<script>
import LoginForm from '~/components/LoginForm.vue';

export default {
  name: 'LoginPage',
  components: {
    LoginForm,
  },
  asyncData({ from, query, redirect, store }) {
    // Redirect if already logged in
    if (store.state.auth.loggedIn) {
      redirect(query.redirect ?? '/');
    }
    // Get previous route
    return { redirect: from ? from.fullPath : '/' };
  },
  data() {
    return {
      title: 'Log in',
      description: 'Log in to your Teach Starter account',
      redirect: '/',
    };
  },
  head() {
    return {
      title: this.title,
      meta: [
        { hid: 'title', name: 'title', content: this.title },
        { hid: 'description', name: 'description', content: this.description },
      ],
      link: this.$hrefLangsPage(this.$route),
    };
  },
  mounted() {
    this.$amplitudeEventPageViewed('Login', this.$route.path, 'Login', 'Account');
  },
};
</script>
